import React, { useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Account/AuthContext';
import {
  SERVICE_URL_PYTHON,
  USER_CURRENT_SESSION_COOKIE,
} from '../../../util/AppConstants';
import {
  ButtonOptions,
  sessionDetailButtonOptions,
  industryOptions,
  caseTypeOptions,
} from './util';
import { PATH_CURRENT_SESSION_WITHOUT_SESSION_ID } from '../../../util/SiteRoutes';
import Subheader from '../../SubHeader';
import SubFooter from '../../SubFooter';

export interface SessionDetails {
  role: string;
  mode: string;
  industry: string;
  companyType: string;
  caseType: string;
}

const CreateSession = () => {
  const { userDetails } = useAuth();
  const navigate = useNavigate();

  const [sessionDetailOptions, setSessionDetailOptions] = useState<
    ButtonOptions[]
  >(sessionDetailButtonOptions);
  const [sessionDetails, setSessionDetails] = useState<SessionDetails>(() => {
    const defaultSessionDetails: SessionDetails = {
      role: '',
      mode: '',
      industry: 'random', // Default value for industry
      companyType: '',
      caseType: 'random',
    };
    sessionDetailOptions.forEach((option) => {
      const selectedButton = option.options.find((button) => button.selected);
      if (selectedButton) {
        defaultSessionDetails[option.label as keyof SessionDetails] =
          selectedButton.label;
      }
    });
    return defaultSessionDetails;
  });

  const createSession = async (userId: string) => {
    // Randomly select an industry if "random" is selected
    let updatedSessionDetails = { ...sessionDetails };
    if (sessionDetails.industry === 'random') {
      const randomIndustry =
        industryOptions[Math.floor(Math.random() * industryOptions.length)];
      updatedSessionDetails.industry = randomIndustry;
    }

    if (sessionDetails.caseType === 'random') {
      if (sessionDetails.role === 'consultant') {
        const randomCaseIndustry =
          caseTypeOptions[Math.floor(Math.random() * caseTypeOptions.length)];
  
        // Set caseType based on random selection for consultant
        if (randomCaseIndustry === 'Market Entry') {
          updatedSessionDetails.caseType = 'market_entry';
        } else if (randomCaseIndustry === 'Growth') {
          updatedSessionDetails.caseType = 'growth';
        } else if (randomCaseIndustry === 'Pricing') {
          updatedSessionDetails.caseType = 'pricing';
        } else {
          updatedSessionDetails.caseType = 'profitability';
        }
      } else {
        // Set caseType to 'null' if not a consultant and random is selected
        updatedSessionDetails.caseType = 'null';
      }
    } else {
      // Map predefined case types to backend format
      if (sessionDetails.caseType === 'Market Entry') {
        updatedSessionDetails.caseType = 'market_entry';
      } else if (sessionDetails.caseType === 'Growth') {
        updatedSessionDetails.caseType = 'growth';
      } else if (sessionDetails.caseType === 'Pricing') {
        updatedSessionDetails.caseType = 'pricing';
      } else {
        updatedSessionDetails.caseType = 'profitability';
      }
    }

    const url = `${SERVICE_URL_PYTHON}/save_session_details`;
    const data = { userId, sessionDetails: updatedSessionDetails }; // Use the updated sessionDetails
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (result?.data.status === 'success') {
        sessionStorage.setItem(USER_CURRENT_SESSION_COOKIE, 'true');
        navigate(
          `${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/${result?.data.sessionId}`
        );
      } else {
        console.error('An unknown error occurred');
        // TODO: Handle session not created
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const handleOptionClick = (
    optionType: keyof SessionDetails,
    value: string,
    active: boolean
  ) => {
    if (active) {
      setSessionDetails((prevState) => ({
        ...prevState,
        [optionType]: value,
      }));
      const updatedOptions = sessionDetailOptions.map((option) => {
        if (option.label === optionType) {
          return {
            ...option,
            options: option.options.map((button) => ({
              ...button,
              selected: button.label === value,
            })),
          };
        }
        return option;
      });
      setSessionDetailOptions(updatedOptions);
    }
  };

  const handleIndustryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSessionDetails((prevState) => ({
      ...prevState,
      industry: event.target.value,
    }));
  };

  const handleCaseTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (sessionDetails.role === 'consultant') {
      setSessionDetails((prevState) => ({
        ...prevState,
        caseType: event.target.value,
      }));
    }
  };

  const startSession = async () => {
    if (userDetails?.user_id) {
      await createSession(userDetails.user_id);
    } else {
      console.error('User details are missing');
    }
  };

  return (
    <>
      <div className='create-session-header'><Subheader /></div>
   
      <div className="container-new-session">
        <div className="container-new-session-inner">
          <div className="container-new-session-inner-details">
            <div className="container-new-session-inner-details-options">
              <div className="container-new-session-inner-details-options-heading">
                Enter session details
              </div>
              {sessionDetailOptions?.map((option) => (
                <div key={option.label}>
                  <div className="container-new-session-inner-details-option">
                    <div className="container-new-session-inner-details-option-label">
                      {option.displayLabel}
                    </div>
                    <div className="container-new-session-inner-details-option-buttons">
                      {option.options.map((button) => (
                        <div
                          key={button.displayLabel}
                          className={
                            'container-new-session-inner-details-option-button' +
                            (button.active
                              ? button.selected
                                ? ' option-button-selected'
                                : ' option-button-not-selected'
                              : '')
                          }
                          onClick={() =>
                            handleOptionClick(
                              option.label as keyof SessionDetails,
                              button.label,
                              button.active
                            )
                          }
                        >
                          {button.displayLabel}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              <div className="container-new-session-inner-details-option option-dropdown">
                <div className="container-new-session-inner-details-option-label">
                  Industry
                </div>
                <div className="container-new-session-inner-details-option-dropdown">
                  <select
                    value={sessionDetails.industry}
                    onChange={handleIndustryChange}
                  >
                    <option value="">Select an Industry (Random)</option>
                    {industryOptions.map((industry, index) => (
                      <option key={index} value={industry}>
                        {industry}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {sessionDetails.role === 'consultant' && (
                <div className="container-new-session-inner-details-option option-dropdown">
                  <div className="container-new-session-inner-details-option-label">
                    Case Type
                  </div>
                  <div className="container-new-session-inner-details-option-dropdown">
                    <select
                      value={sessionDetails.caseType}
                      onChange={handleCaseTypeChange}
                    >
                      <option value="">Select a Case Type (Random)</option>
                      {caseTypeOptions.map((caseType, index) => (
                        <option key={index} value={caseType}>
                          {caseType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div
              className="container-new-session-inner-details-button"
              onClick={startSession}
            >
              Start session
            </div>
          </div>
        </div>
      </div>
      <div className='create-session-footer'><SubFooter /></div>
      
    </>
  );
};

export default CreateSession;
