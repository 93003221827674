import React from 'react';
import './NewHome.css';
import InfoBar from './InfoBar/InfoBar';
import NavBar from './NavBar/NavBar';
import MainContent from './MainContent/MainContent';
import Footer from './Footer/Footer';
import BackgroundCircles from '../BackgroundImage/BackgroundCircles';

const NewHomePage: React.FC = () => {
  return (
    <div className="new-homepage">
      <BackgroundCircles />
      <InfoBar />
      <div className="new-homepage-container">
        <NavBar />
        <MainContent />
      </div>
      <Footer />
    </div>
  );
};

export default NewHomePage;
