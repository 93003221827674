import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Account from './components/Account';
import CreateSession from './components/Session/CreateSession';
import GeneralCurrentSession from './components/Session/GeneralCurrentSession';
import UserWaitlist from './components/UserWaitlist';
import EnterpriseLogin from './components/EnterpriseLogin/EnterpriseLogin';
import VerifyOTP from './components/VerifyOTP/VerifyOTP';
import { useAuth } from './components/Account/AuthContext';
import './App.css';
import './util/Fonts';
import Rate from './components/Session/Rate';
import Summary from './components/Session/Summary';
import NotificationHandler from './components/NotificationOnTop/NotificationHandler';
import {
  PATH_HOME,
  PATH_LOGIN,
  PATH_CREATE_SESSION,
  PATH_CURRENT_SESSION_WITH_SESSION_ID,
  PATH_USER_WAITLIST,
  PATH_ENTERPRISE_LOGIN,
  PATH_VERIFY_OTP,
  PATH_DISCLAIMER,
  PATH_DEFAULT,
  PATH_USER_RATE,
  PATH_CURRENT_SESSION_SUMMARY,
  PATH_ONGOING_SESSION,
  PATH_NEW_HOMEPAGE,
  PATH_AUTH_COMPONENT,
  PATH_AUTH_DashBoard,
  PATH_DEVICE_INFO,
  PATH_DEMO_DIRECT_LINK,
} from './util/SiteRoutes';
import ProtectedRoute from './components/RouteProtection/ProtectedRoute';
import { USER_LOGIN_WAY_KEY } from './util/AppConstants';
import { EnterpriseCurrentSession } from './components/Session/EnterpriseCurrentSession';
import NewHomePage from './components/NewHome/NewHome';
import AuthComponent from './components/RecruiterRegisteration/AuthComponent';
import DashBoard from './components/RandomTesting/DashBoard';
import DeviceInfo from './util/DeviceInfo';
import DemoDirectLink from './components/DemoDirectLink/DemoDirectLink';

const AuthComponentWrapper = () => {
  const loginWay = localStorage.getItem('loginWay');
  const navigate = useNavigate();

  useEffect(() => {
    if (loginWay === 'Recruiter') {
      navigate(PATH_AUTH_DashBoard, { replace: true });
    }
  }, [loginWay, navigate]);

  return <AuthComponent />;
};

const DashBoardComponentWrapper = () => {
  const [loading, setLoading] = useState(true);
  const loginWay = localStorage.getItem('loginWay');
  const navigate = useNavigate();

  useEffect(() => {
    if (loginWay !== 'Recruiter') {
      navigate(PATH_AUTH_COMPONENT, { replace: true });
    } else {
      setLoading(false); // Stop loading if valid user
    }
  }, [loginWay, navigate]);

  // Show a loading indicator or nothing until the login check is complete
  if (loading) {
    return <div>Loading...</div>; // Or simply return null if no loading UI is required
  }

  return <DashBoard />;
};


function App() {
  const { userDetails } = useAuth();

  const siteRoutes = [
    { path: PATH_HOME, element: <NewHomePage /> },
    {
      path: PATH_LOGIN,
      element: (() => {
        const loginWay = localStorage.getItem(USER_LOGIN_WAY_KEY);
        const sessionKey = localStorage.getItem('espello-user-session');
        const isLoggedIn = localStorage.getItem('loggedIn') === 'true';
        
        // Redirect normalLogin users with an active session to /session/create-session
        // if (loginWay === 'normalLogin' && sessionKey) {
        //   return <Navigate to={PATH_CREATE_SESSION} replace />;
        // }
        if (isLoggedIn && sessionKey) {
          localStorage.setItem('loginWay', 'normalLogin');
          return <Navigate to={PATH_CREATE_SESSION} replace />;
        }
        
        // Otherwise show the login page
        localStorage.removeItem('isVerified');
        localStorage.removeItem('source');
        return <Account />;
      })(),
    },
    {
      path: PATH_AUTH_COMPONENT,
      element: <AuthComponentWrapper />,
    },
    {
      path: PATH_AUTH_DashBoard,
      element: <DashBoardComponentWrapper />,
    },
    {
      path: PATH_ENTERPRISE_LOGIN,
      element: (() => {
        const loginWay = localStorage.getItem(USER_LOGIN_WAY_KEY);
        const sessionKey = localStorage.getItem('espello-user-session');
        
        // Redirect enterpriseLogin users with an active session to /session/current-session/ongoing
        if (loginWay === 'enterpriseLogin' && sessionKey) {
          return <Navigate to={PATH_ONGOING_SESSION} replace />;
        }
        
        // Otherwise show the enterprise login page
        return <EnterpriseLogin />;
      })(),
    },
    {
      path: PATH_CREATE_SESSION,
      element: (() => {
        const loginWay = localStorage.getItem(USER_LOGIN_WAY_KEY);
        if (loginWay === null || (loginWay !== 'normalLogin' && loginWay !== 'Recruiter' && loginWay !== 'demo' && loginWay !== 'enterpriseLogin')) {
          return <Navigate to={PATH_LOGIN} replace />;
        }
        return userDetails !== null ? (
          <CreateSession />
        ) : (
          <Navigate to={PATH_LOGIN} replace />
        );
      })(),
    },
    {
      path: PATH_ONGOING_SESSION,
      element: (
        <ProtectedRoute
          element={<EnterpriseCurrentSession />}
          condition={() => !!localStorage.getItem('espello-user-session')}
          redirectTo={PATH_ENTERPRISE_LOGIN}
        />
      ),
    },
    {
      path: PATH_CURRENT_SESSION_WITH_SESSION_ID,
      element: (() => {
        const userSession = localStorage.getItem('espello-user-session');
        const loginWay = localStorage.getItem('loginWay');
        const userID = localStorage.getItem('userID');
        
        // Check if user session exists or if loginWay and userID are valid
        const condition = !!userSession || (loginWay === 'Recruiter' && !!userID);
        
        // Determine redirect path based on loginWay
        let redirectTo;
        switch (loginWay) {
          case 'Recruiter':
            redirectTo = PATH_AUTH_COMPONENT;
            break;
          case 'demo':
            redirectTo = PATH_DEMO_DIRECT_LINK; // Add your demo component path here
            break;
          case 'normalLogin':
          default:
            redirectTo = PATH_LOGIN; // Default to login if anything else
            break;
        }
    
        return (
          <ProtectedRoute
            element={<GeneralCurrentSession />}
            condition={() => condition}
            redirectTo={redirectTo} // Pass the dynamically calculated path as a string
          />
        );
      })(),
    },
      
    { path: PATH_USER_WAITLIST, element: <UserWaitlist /> },
    { path: PATH_VERIFY_OTP, element: <VerifyOTP /> },
    { path: PATH_DEFAULT, element: <Navigate to={PATH_HOME} replace /> },
    {
      path: PATH_USER_RATE,
      element: (
        <ProtectedRoute
          element={<Rate />}
          condition={() => !!localStorage.getItem('espello-user-session')}
          redirectTo={PATH_LOGIN}
        />
      ),
    },
    {
      path: PATH_CURRENT_SESSION_SUMMARY,
      element: (
        <ProtectedRoute
          element={<Summary />}
          condition={() => !!localStorage.getItem('espello-user-session')}
          redirectTo={PATH_LOGIN}
        />
      ),
    },
    { path: PATH_DEVICE_INFO, element: <DeviceInfo /> },
    { path: PATH_NEW_HOMEPAGE, element: <NewHomePage /> },
    { path: PATH_DEMO_DIRECT_LINK, element: <DemoDirectLink /> },
  ];

  return (
    <div className="container">
      <BrowserRouter>
        <NotificationHandler />
        <Routes>
          {siteRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
