import React from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { SERVICE_URL_PYTHON, USER_CURRENT_SESSION_COOKIE, USER_LOGIN_WAY_KEY } from '../../util/AppConstants';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useAuth } from './AuthContext';
import { PATH_CURRENT_SESSION_WITHOUT_SESSION_ID, PATH_HOME } from '../../util/SiteRoutes';

export interface LoginParams {
  user: JwtPayload;
  user_id: string;
}

interface GoogleComponentProps {
  redirectPath: string;
  loginWay?: string;
}

interface CustomJwtPayload extends JwtPayload {
  email?: string;
}

const GoogleComponent: React.FC<GoogleComponentProps> = ({
  redirectPath,
  loginWay,
}) => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSuccessResponse = async (response: CredentialResponse) => {
    const token = response.credential as string;
    try {
      const res = await fetch(`${SERVICE_URL_PYTHON}/verify_google_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `credential=${token}`,
        redirect: 'manual',
      });

      if (res.ok) {
        const result = await res.json();
        if (result && result.user_id) {
          const loginParams: LoginParams = {
            user: jwtDecode(token) as JwtPayload,
            user_id: result.user_id,
          };
          login(loginParams);

          const decodedToken = jwtDecode<CustomJwtPayload>(token);
          const email = decodedToken.email;
          localStorage.setItem('userId',result.user_id)
          localStorage.setItem('loggedIn', 'true');
          if (email) {
            localStorage.setItem('loggedIn', 'true');
            navigate(redirectPath, { state: { email } });
            localStorage.setItem('email', email);
            localStorage.setItem(USER_LOGIN_WAY_KEY, loginWay || '');

            if (loginWay === 'demo') {
              localStorage.setItem('userId',result.user_id)
              localStorage.setItem('loggedIn', 'true');
              await createSession(result.user_id, email);  // Pass user_id and email
            }

          } else {
            console.error('Email not found in the token');
            alert(
              'Ahh, you’re interested. Please join the waitlist as we are operating in a closed user group'
            );
            navigate(PATH_HOME);
          }
        } else {
          console.error('User ID not found in the response');
          alert(
            'Ahh, you’re interested. Please join the waitlist as we are operating in a closed user group'
          );
          navigate(PATH_HOME);
        }
      } else {
        console.error('Error logging in from Google');
        alert(
          'Ahh, you’re interested. Please join the waitlist as we are operating in a closed user group'
        );
        navigate(PATH_HOME);
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      navigate(PATH_HOME);
    }
  };

  const createSession = async (userId: string, email: string) => {
    const url = `${SERVICE_URL_PYTHON}/save_session_details`;
    const sessionDetails = {
      role: 'demo',
      mode: '',
      industry: '',
      companyType: '',
    };
  
    const data = { userId, sessionDetails };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
  
      if (result?.data.status === 'success') {
        sessionStorage.setItem(USER_CURRENT_SESSION_COOKIE, 'true');
        console.log(result.data.sessionId);
        localStorage.setItem('loggedIn', 'true');
  
        // Navigate to session path after successful session creation
        navigate(`${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/${result?.data.sessionId}`);
      } else {
        console.error('An unknown error occurred');
        // TODO: Handle session not created
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleErrorResponse = () => {
    console.error('Error during Google authentication');
  };

  return (
    <div style={{ position: 'relative', width: '320px' }}>
      <div className="custom-google-cover">Sign In With Google</div>
      <GoogleLogin
        onSuccess={handleSuccessResponse}
        onError={handleErrorResponse}
        width={320}
      />
    </div>
  );
};

export default GoogleComponent;
