import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { ConversationTurnContextModel } from '../../../model/ConversationTurnContextModel';
import VideoRecorder from '../../Recorder/VideoRecorder';
import ChatContainer from './ChatContainer';
import ChatHeader from './ChatHeader';
import { Loader } from '../../Loader';
import {
  PATH_CREATE_SESSION,
  PATH_ENTERPRISE_LOGIN,
  PATH_ONGOING_SESSION,
  PATH_USER_RATE,
} from '../../../util/SiteRoutes';
import {
  SERVICE_URL_PYTHON,
  SPEAKER_INITIAL_TEXT,
  USER_CURRENT_SESSION_COOKIE,
  USER_LOGIN_WAY_KEY,
} from '../../../util/AppConstants';
import { validateSessionId } from './util';
import { InterviewerResponse } from '../../../model/InterviewerResponse';
import { Role } from '../../../model/Role';
import { ConversationTurn } from '../../../model/ConversationTurn';
import { SessionTranscript } from '../../../model/SessionTranscript';
import { Transcript } from '../../../model/Transcript';
import { SessionDetails } from '../CreateSession';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ExitMode } from '../../../model/ExitMode';
import { Model } from '../../Model';
import GeneralDisclaimer from './Disclaimer/General';

const GeneralCurrentSession = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state: locationState } = location;
  const { sessionId } = useParams();

  const videoRecorderRef = useRef<{
    handleSaveVideo: () => void;
    stopRecording: () => void;
  } | null>(null);

  const [timerOut, setTimerOut] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCurrentSessionChatVisible, setIsCurrentSessionChatVisible] =
    useState<boolean>(true);
  const [sessionTranscript, setSessionTranscript] = useState<SessionTranscript>(
    { transcript: [{ role: Role.SPEAKER, text: SPEAKER_INITIAL_TEXT }] }
  );
  const [conversationTurn, setConversationTurn] = useState<ConversationTurn>(
    ConversationTurn.IDLE
  );
  const [sessionDetails, setSessionDetails] = useState<SessionDetails>(
    {} as SessionDetails
  );
  const [state, setState] = useState({
    isDialogVisible: true,
    showReenterFullscreenPrompt: false,
  });
  const [interviewerText, setInterviewerText] = useState<string>('');
  const exitAttempts = useRef<number>(0);
  const fullscreenActive = useRef<boolean>(false);

  const isMobileScreen = () => window.innerWidth <= 480;
  const handleStartSessionClick = () => {
    proceedWithSession();
    if (!isMobileScreen()) {
      enterFullscreen();
    }
  };

  const enterFullscreen = () => {
    if (isMobileScreen()) return;
    const elem = document.documentElement; // or the specific element you want to make fullscreen
    fullscreenActive.current = true;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any).mozRequestFullScreen) {
      // Firefox
      (elem as any).mozRequestFullScreen();
    } else if ((elem as any).webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) {
      // IE/Edge
      (elem as any).msRequestFullscreen();
    }
  };

  const exitHandler = () => {
    if (
      !document.fullscreenElement &&
      !(document as any).webkitIsFullScreen &&
      !(document as any).mozFullScreen &&
      !(document as any).msFullscreenElement
    ) {
      fullscreenActive.current = false;
      exitAttempts.current += 1;

      if (exitAttempts.current) {
        setState((prevState) => ({
          ...prevState,
          showReenterFullscreenPrompt: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showReenterFullscreenPrompt: false,
        }));

        setTimeout(() => {
          alert(
            'You have exceeded the maximum number of attempts to exit fullscreen.'
          );
        }, 1000);

        // Provide some time to stop and save the recording
        setTimeout(() => {
          navigate(PATH_USER_RATE, {
            state: { sessionId: sessionId, exitMode: ExitMode.MANUAL },
          });
        }, 2000);
      }
    }
  };

  const exitFullscreen = () => {

    if (isMobileScreen()) return;

    fullscreenActive.current = false;
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    }
  };

  const handleReenterFullscreenClick = () => {
    setState((prevState) => ({
      ...prevState,
      showReenterFullscreenPrompt: false,
    }));
    if (!isMobileScreen()) {
      enterFullscreen();
    }
  };

  const proceedWithSession = () => {
    setState((prevState) => ({
      ...prevState,
      isDialogVisible: false,
    }));
    sendIntervieweeResponse(`Hi ${sessionDetails.industry}`);
  };

  const addSessionTranscript = (role: Role, text: string) => {
    const intervieweeTranscript: Transcript = { role, text };
    const tempTranscript: Transcript[] =
      sessionTranscript?.transcript as Transcript[];

    tempTranscript?.push(intervieweeTranscript);

    const tempSessionTranscript: SessionTranscript = {
      transcript: tempTranscript,
    };

    setSessionTranscript(tempSessionTranscript);
  };

  const changeConversationTurn = (turn: ConversationTurn) => {
    setConversationTurn(turn);
  };

  let previousResponse = '';

  const sendIntervieweeResponse = async (
    intervieweeText: string,
    retries = 4
  ) => {
    changeConversationTurn(ConversationTurn.WAITING);
    addSessionTranscript(Role.INTERVIEWEE, intervieweeText);

    const url = `${SERVICE_URL_PYTHON}/process_text`;
    const data = {
      message: intervieweeText,
      session_id: sessionId,
    };

    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        const fetchedInterviewerText: InterviewerResponse = responseData;
        let interviewerResponse: string = fetchedInterviewerText.message;

        // Always set the state, even if the response is the same
        setInterviewerText((prevText) => {
          // Adding a unique key to force re-render
          if (interviewerResponse === previousResponse) {
            // Step 3: Modify the response if it's the same as the previous one
            const uniqueSuffix = `.`;
            interviewerResponse += uniqueSuffix;
          }
          return interviewerResponse;
        });

        // Adding session transcript for interviewer
        addSessionTranscript(Role.SPEAKER, interviewerResponse);

        previousResponse = interviewerResponse;

        break; // If the request is successful, exit the loop
      } catch (error) {
        console.error(`Error on attempt ${attempt}:`, error);
        if (attempt === retries) {
          console.error('All retry attempts failed');
        }
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem(USER_CURRENT_SESSION_COOKIE) !== 'true') {
      if (localStorage.getItem(USER_LOGIN_WAY_KEY) === 'enterpriseLogin') {
        navigate(PATH_ENTERPRISE_LOGIN);
      } else {
        if (localStorage.getItem(USER_LOGIN_WAY_KEY) === 'enterpriseLogin') {
          navigate(PATH_ONGOING_SESSION);
        } else {
          navigate(PATH_CREATE_SESSION);
        }
      }

      return;
    }
  }, [state, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = ''; // Show confirmation dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);

      // Exit fullscreen when the component unmounts
      exitFullscreen();
    };
  }, []);

  useEffect(() => {
    const validateAndFetchThread = async () => {
      const validationResult = await validateSessionId(sessionId);
      if (
        validationResult.status === 'success' ||
        localStorage.getItem(USER_LOGIN_WAY_KEY) === 'enterpriseLogin'
      ) {
        setLoading(false);
        setSessionDetails(validationResult.data.sessionDetails);
      } else {
        alert(
          validationResult.errorDescription ||
            'Invalid session ID, Try creating new session'
        );
        alert(
          validationResult.errorDescription ||
            'Invalid session ID, Try creating new session'
        );
        navigate(PATH_CREATE_SESSION);
      }
    };

    validateAndFetchThread();
  }, [sessionId, navigate]);

  // Window blur and focus event handlers
  useEffect(() => {
    const handleWindowBlur = () => {
      if (fullscreenActive.current) {
        console.log('Window lost focus');
        exitAttempts.current += 1;

        if (exitAttempts.current) {
          setState((prevState) => ({
            ...prevState,
            showReenterFullscreenPrompt: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            showReenterFullscreenPrompt: false,
          }));

          setTimeout(() => {
            alert(
              'You have exceeded the maximum number of attempts to exit fullscreen.'
            );
          }, 1000);

          // Provide some time to stop and save the recording
          setTimeout(() => {
            navigate(PATH_USER_RATE, {
              state: { sessionId: sessionId, exitMode: ExitMode.MANUAL },
            });
          }, 2000);
        }
      }
    };

    const handleWindowFocus = () => {
      // Handle window gaining focus
      console.log('Window gained focus');
    };

    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);

  const handleStopRecording = () => {
    if (videoRecorderRef.current) {
      videoRecorderRef.current.stopRecording();
      // Provide some time to ensure the recording stops gracefully
      setTimeout(() => {
        console.log('Recording stopped and data sent to server.');
        // Any additional cleanup or notification logic can go here
      }, 1000);
    }
  };

  if (loading) {
    return <Loader message="Validating session details" />;
  }

  return (
    <React.Fragment>
      {sessionId !== undefined && isCurrentSessionChatVisible ? (
        <div className="chat-bot-container">
          {state.isDialogVisible ? (
            <GeneralDisclaimer
              handleStartSessionClick={handleStartSessionClick}
            />
          ) : (
            <>
              <ChatHeader
                conversationContext={
                  {
                    conversationTurn,
                    changeConversationTurn,
                  } as ConversationTurnContextModel
                }
                setTimerOut={setTimerOut}
                session_id={sessionId}
                sessionDetails={sessionDetails}
                setIsCurrentSessionChatVisible={setIsCurrentSessionChatVisible}
                handleStopRecording={handleStopRecording}
              />
              <div
                style={{
                  position: 'absolute',
                  width: '200px',
                  height: '125px',
                  left: '0',
                  top: '90px',
                }}
              ></div>
              <ChatContainer
                timerOut={timerOut}
                session_id={sessionId}
                interviewerText={interviewerText}
                sendIntervieweeResponse={sendIntervieweeResponse}
                conversationContext={
                  {
                    conversationTurn,
                    changeConversationTurn,
                  } as ConversationTurnContextModel
                }
                stopRecording={handleStopRecording}
              />
            </>
          )}
          <Model
            message={`You have exited fullscreen mode ${exitAttempts.current} times. Please click the button to re-enter fullscreen.`}
            actionContent="Re-enter Fullscreen"
            action={handleReenterFullscreenClick}
            visible={state.showReenterFullscreenPrompt}
          />
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default GeneralCurrentSession;
