import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom'; // Added useNavigate for navigation
import GoogleComponent from '../Account/GoogleComponent';
import {
  GOOGLE_CLIENT_ID,
  SERVICE_URL_PYTHON,
  SESSION_SECRET_KEY,
  USER_CURRENT_SESSION_COOKIE,
  USER_LOGIN_WAY_KEY,
  USER_SESSION_KEY,
} from '../../util/AppConstants';
import { PATH_CREATE_SESSION, PATH_CURRENT_SESSION_WITHOUT_SESSION_ID } from '../../util/SiteRoutes';
import googleLogo from '../Account/google-logo.png';
import SubFooter from '../SubFooter';
import CryptoJS from 'crypto-js';
import BackgroundCircles from '../BackgroundImage/BackgroundCircles';
import NavBar from '../NewHome/NavBar/NavBar';


const Login = () => {
  const [showOtpScreen, setShowOtpScreen] = useState(false); // State to toggle OTP screen
  const [email, setEmail] = useState(''); // Store email input
  const [otp, setOtp] = useState(''); // State to store OTP
  const [emailVerified, setEmailVerified] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isResendActive, setIsResendActive] = useState(true);
  const [userId, setUserId] = useState(null); // State to store userId
  const navigate = useNavigate(); // Use navigate hook

  if (!GOOGLE_CLIENT_ID) {
    console.error('Google Client ID is not defined');
    return null;
  }


  // UseEffect to handle resendOTP when userId is set
  useEffect(() => {
    if (userId) {
      handleResendOtp(userId); // Trigger resend OTP when userId is set but OTP screen is not shown
    }
  }, [userId]);

  const handleSendOtp = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      alert('Enter your email address.');
      return;
    }
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      // First API call to register
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/register',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: 'userName',
            email,
            registrationMedium: 'email',
          }),
        }
      );

      const data = await response.json();

      if (data.status === 'success') {
        // If first API call is successful, store userId and show OTP screen
        setUserId(data.data.userId);
        localStorage.setItem('userId', data.data.userId);
        setShowOtpScreen(true);
        setIsResendActive(false);
      } else if (data.status === 'failed') {
        // If first API fails, set userId for resendOTP
        setUserId(data.data.userId);
      } else {
        alert(`Error: ${data.data.errorDescription}`);
      }
    } catch (error) {
      console.error('Error during registration process:', error);
    }
  };

  // New function to handle resendOTP after userId is set
  const handleResendOtp = async (userId:string) => {
    try {
      const resendResponse = await fetch(
        'https://espello.co/java_service/registration/api/v1/resendOTP',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId,
            email,
            verificationModule: 'email',
          }),
        }
      );

      const resendData = await resendResponse.json();
      if (resendData.status === 'success') {
        // If resend OTP API is successful, set timer and update UI
        setTimer(60);
        setIsResendActive(false);
        setShowOtpScreen(true);
      } else {
        alert(`Error: ${resendData.data.errorDescription}`);
      }
    } catch (error) {
      console.error('Error during resend OTP process:', error);
    }
  };

  const handleVerifyOtp = async () => {
    if (!userId) {
      alert('User ID is missing, please try again.');
      return;
    }
  
    try {
      const response = await fetch(
        'https://espello.co/java_service/registration/api/v1/verifyOTP',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId,
            email,
            verificationModule: 'email',
            otp,
          }),
        }
      );
  
      const data = await response.json();
  
      if (data.data.verified === true) {
        const loginParams = {
          user: { email },
          user_id: userId,
        };
  
        localStorage.setItem('userId', userId);
        // Encrypt user data and store in localStorage
        const encryptedUserData = CryptoJS.AES.encrypt(
          JSON.stringify(loginParams),
          SESSION_SECRET_KEY
        ).toString();
  
  
        // Store the encrypted session in localStorage
        localStorage.setItem(USER_SESSION_KEY, encryptedUserData);
  
        // Save email and login method
        localStorage.setItem('email', email);
        localStorage.setItem(USER_LOGIN_WAY_KEY, 'demo');
  
        const startSession = async () => {
          if (
            localStorage.getItem('loginWay') == 'demo' &&
            localStorage.getItem('email')
          ) {
            await createSession();
          } else {
            console.error('User details are missing');
          }
        };

        await startSession();

      } else {
        alert('OTP verification failed');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  useEffect(() => {
    const email = localStorage.getItem('email');
    const session = localStorage.getItem('espello-user-session');
    const loggedIn = localStorage.getItem('loggedIn') === 'true';

    if (email && session && loggedIn) {
      // If session and email exist in localStorage, create session
      localStorage.setItem('loginWay','demo')
      createSession();
    } else {
      // Otherwise, redirect to login
      navigate('/demo'); // Adjust the path as needed
    }
  }, [navigate]);

  const createSession = async () => {
    const userId = localStorage.getItem('userId');
    const url = `${SERVICE_URL_PYTHON}/save_session_details`;
    const sessionDetails = {
      role: `demo`,
      mode: '',
      industry: '',
      companyType: '',
    };

    const data = { userId, sessionDetails };
    
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
  
      if (result?.data.status === 'success') {
        sessionStorage.setItem(USER_CURRENT_SESSION_COOKIE, 'true');
        localStorage.setItem('loggedIn', 'true');
  
        navigate(`${PATH_CURRENT_SESSION_WITHOUT_SESSION_ID}/${result?.data.sessionId}`);
      } else {
        console.error('An unknown error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleGoBackToEmailInput = () => {
    // Clear all relevant states to reset the flow
    setShowOtpScreen(false);
    setEmail('');           // Clear email
    setOtp('');             // Clear OTP
    setUserId(null);        // Clear userId
    setTimer(0);            // Reset the timer
    setIsResendActive(true); // Reactivate resend OTP when going back to email input
  };

  return (
    <React.Fragment>
      <BackgroundCircles />
      <NavBar />
      {!showOtpScreen && (
        <div className="espello-login-wrapper">
          <div className="espello-login-box">
            <h2 className="espello-login-heading">Login to Espello</h2>
            <div className="google-login-wrapper">
              <img
                src={googleLogo}
                alt="Google Icon"
                className="google-login-icon"
                style={{ backgroundColor: '#f4eae7' }}
              />
              <span className="google-login-overlay-text">
                Continue with Google
              </span>
            </div>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleComponent
                redirectPath={PATH_CREATE_SESSION}
                loginWay="demo"
              />
            </GoogleOAuthProvider>

            <div className="espello-divider">
              <span className="espello-divider-text">or</span>
            </div>

            <input
              type="email"
              className="email-input-box"
              placeholder="example@domain.com"
              autoComplete="on"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Updated email input handling
            />
            <button
              className="send-otp-button"
              style={{ color: 'black' }}
              onClick={handleSendOtp}
            >
              Continue with email
            </button>
          </div>
        </div>
      )}

      {showOtpScreen && (
        <div className="otp-overlay">
          <div className="otp-container">
            <p style={{ color: '#f4eae7' }}>
              Enter the code generated from the link sent to{' '}
              <span
                style={{
                  fontWeight: 'bold',
                  display: 'inline-block',
                  marginTop: '5px',
                }}
              >
                {email}
              </span>
            </p>
            <input
              className="otp-email-input-box"
              type="text"
              placeholder="Enter verification code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)} // Handle OTP input
            />
            <button className="otp-submit-button" onClick={handleVerifyOtp}>
              Verify Email Address
            </button>
            <div
              onClick={handleGoBackToEmailInput}
              className="resend-active"
              style={{
                color: '#f4eae7',
                fontSize: '13px',
                marginTop: '10px',
                marginBottom: '20px',
                letterSpacing: '0.2px',
              }}
            >
              <span style={{ textDecoration: 'none', cursor: 'default'}}>
                Not seeing the email in your inbox?
              </span>{' '}
              <span style={{ textDecoration: 'underline' }}>
                Try sending again.
              </span>
            </div>
          </div>
        </div>
      )}

      <SubFooter />
    </React.Fragment>
  );
};

export default Login;
