import React, { FC, useEffect, useState } from 'react';
import { ConversationTurnContextModel } from '../../../../model/ConversationTurnContextModel';
import { ConversationTurn } from '../../../../model/ConversationTurn';
import ChatHeaderTimer from './ChatHeaderTimer';
import { SessionDetails } from '../../CreateSession';
import { useNavigate } from 'react-router-dom';
import { PATH_USER_RATE } from '../../../../util/SiteRoutes';
import { ExitMode } from '../../../../model/ExitMode';

interface ChatHeaderProps {
  conversationContext: ConversationTurnContextModel;
  session_id: string;
  sessionDetails: SessionDetails;
  setTimerOut: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCurrentSessionChatVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleStopRecording: () => void;
}

const ChatHeader: FC<ChatHeaderProps> = ({
  conversationContext,
  session_id,
  sessionDetails,
  setTimerOut,
  handleStopRecording,
}) => {
  const navigate = useNavigate();
  const [saveRecordingStatus, setSaveRecordingStatus] =
    useState<boolean>(false);
  const [lineWidth, setLineWidth] = useState<number>(100);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    // Detect if the user is on a mobile device based on screen width
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to check on window resize
    window.addEventListener('resize', checkIfMobile);

    // Initial check
    checkIfMobile();

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const onExitSession = () => {
    setSaveRecordingStatus(true);
    handleStopRecording();
    setTimeout(() => {
      setSaveRecordingStatus(false);
      navigate(PATH_USER_RATE, {
        state: { sessionId: session_id, exitMode: ExitMode.MANUAL },
      });
    }, 2000);
  };

  return (
    <div className="chat-bot-container-header">
      <div className="chat-bot-container-header-main">
        <div className="chat-bot-container-header-main-left">
          <div className="chat-bot-container-header-main-left-content">
            {`${
              localStorage.getItem('loginWay') === 'enterpriseLogin'
                ? 'Screening Round- Quash'
                : `Session - ${sessionDetails?.role}  Interview`
            }`}
          </div>
        </div>
        {conversationContext?.conversationTurn ===
        ConversationTurn.INTERVIEWEE ? (
          <ChatHeaderTimer
            conversationContext={conversationContext}
            setTimerOut={setTimerOut}
            setLineWidth={setLineWidth}
          />
        ) : (
          <div className="chat-bot-container-header-main-centre"></div>
        )}
        <div
          className="chat-bot-container-header-main-right"
          onClick={onExitSession}
        >
          {saveRecordingStatus ? (
            <span style={{ color: '#FF8371' }}>{`Exiting...`}</span>
          ) : (
            <>
              <div className="chat-bot-container-header-main-right-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M11.4988 11.5012L4.49728 4.49963"
                    strokeWidth="1.00189"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.4988 4.49963L4.49728 11.5012"
                    strokeWidth="1.00189"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="chat-bot-container-header-main-right-content">
              {isMobile ? 'Exit' : 'Exit Interview'}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="chat-bot-container-header-separator-wrapper">
        <div
          className="chat-bot-container-header-separator"
          style={{ width: `${lineWidth}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ChatHeader;
