import React, { useState, useEffect } from 'react';
import Header from "../Header";
import './index.css';
import Login from './Login';

import BackgroundCircles from '../BackgroundImage/BackgroundCircles';
import NavBar from '../NewHome/NavBar/NavBar';
import favicon from '../Account/favicon.png'
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from '../../util/SiteRoutes';

const Account = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial state based on window size

    // Detect screen resize to toggle mobile view
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <><BackgroundCircles />
            {isMobile ? (
                <div className="user-waitlist-logo-container">
                    <img
                        src={favicon}
                        alt="Espello Logo"
                        className="user-waitlist-logo"
                        onClick={() => navigate(PATH_HOME)}
                    />
                </div>
            ) : (
                <NavBar />
            )}
            
            <Login />
        </>
    );
};

export default Account;
