import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import './index.css';
import { ConversationTurn } from '../../../../../model/ConversationTurn';
import { ConversationTurnContextModel } from '../../../../../model/ConversationTurnContextModel';
const annyang = require('annyang');

export interface AnswerProps {
  timerOut: boolean;
  sendIntervieweeResponse: (
    intervieweeText: string,
    retries: number
  ) => Promise<void>;
  conversationContext: ConversationTurnContextModel;
}

const Answer: FC<AnswerProps> = ({
  timerOut,
  sendIntervieweeResponse,
  conversationContext,
}) => {
  const [interimTranscript, setInterimTranscript] = useState<string>('');
  const [finalTranscript, setFinalTranscript] = useState<string>('');
  const [isSpeechRecognitionActive, setIsSpeechRecognitionActive] =
    useState<boolean>(false);
  const [lastSentMessage, setLastSentMessage] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [deviceType, setDeviceType] = useState<string>('Desktop');
  const [inputLength, setInputLength] = useState<number>(0);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const textarea = event.target as HTMLTextAreaElement;
    const currentText = textarea.value;

    // Prevent modification of the existing text (backspace, delete, etc.)
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      (event.key.length === 1 && textarea.selectionStart < inputLength)
    ) {
      event.preventDefault();
    }
  };

  const getDeviceType = (): string => {
    const isMobile = () => {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor);
      return check;
    };
    const userAgent = navigator.userAgent.toLowerCase();
    if (isMobile()) return 'Mobile';
    if (/tablet/.test(userAgent)) return 'Tablet';
    return 'Desktop';
  };

  const getBrowserName = (): string => {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('OPR') > -1 || userAgent.indexOf('Opera') > -1)
      return 'Opera';
    if (userAgent.indexOf('Edg') > -1) return 'Edge';
    if (
      userAgent.indexOf('Chrome') > -1 &&
      userAgent.indexOf('OPR') === -1 &&
      userAgent.indexOf('Edg') === -1
    )
      return 'Chrome';
    if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1)
      return 'Safari';
    if (userAgent.indexOf('Firefox') > -1) return 'Firefox';
    if (userAgent.indexOf('Trident') > -1) return 'Internet Explorer';
    return 'Unknown';
  };

  useEffect(() => {
    getDeviceType();
    getBrowserName();
  }, []);

  useEffect(() => {
    const detectedDeviceType = getDeviceType(); // Detect the device type
    setDeviceType(detectedDeviceType); // Store the device type in the state
  }, []);

  // Initialize SpeechRecognition or Annyang
  const recognition = useMemo(() => {
    const deviceType = getDeviceType();
    const browserName = getBrowserName();

    // Check if SpeechRecognition is available
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (deviceType === 'Mobile' || browserName !== 'Chrome') {
      // Use annyang for non-Chrome browsers or mobile devices
      if (annyang && annyang.isListening !== undefined) {
        console.info('Using annyang for speech recognition.');
        return annyang;
      } else if (SpeechRecognition) {
        console.warn('Annyang not supported, falling back to Web Speech API');
        return new SpeechRecognition();
      } else {
        console.error('Speech recognition not supported in this browser.');
        return null;
      }
    } else {
      // Use SpeechRecognition for Chrome on desktop
      console.log('Using Chrome Web Speech API for recognition.');
      return new SpeechRecognition();
    }
  }, []);

  // Initialize event listeners for speech recognition
  useEffect(() => {
    if (!recognition) return;

    const handleResult = (event: any) => {
      if (recognition === annyang) {
        // Handle annyang result
        setFinalTranscript((prev) => prev + ' ' + event);
      } else {
        // Handle Web Speech API result
        let interim = '';
        for (let i = 0; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            if (i === event.results.length - 1)
              setFinalTranscript(
                (prev) => prev + ' ' + event.results[i][0].transcript
              );
          } else interim += ' ' + event.results[i][0].transcript;
        }
        setInterimTranscript(interim);
      }
    };

    const handleStart = () => {
      console.info('Speech recognition started.');
    };

    const handleError = (event: any) => {
      console.error('Speech recognition error:', event.error);
    };

    const handleEnd = () => {
      console.info('Speech recognition ended.');
      setIsSpeechRecognitionActive(false);
      if (
        conversationContext?.conversationTurn === ConversationTurn.INTERVIEWEE
      ) {
        recognition.start();
        setIsSpeechRecognitionActive(true);
      } else {
        recognition.stop();
      }
    };

    // Setup listeners
    if (recognition === annyang) {
      annyang.addCommands({
        '*text': handleResult,
      });
    } else {
      recognition.onresult = handleResult;
      recognition.onstart = handleStart;
      recognition.onerror = handleError;
      recognition.onend = handleEnd;
    }

    return () => {
      if (recognition === annyang) {
        annyang.abort();
      } else {
        recognition.abort();
      }
    };
  }, [recognition, conversationContext?.conversationTurn]);

  // Start recognition based on conversation turn
useEffect(() => {
  if (
    conversationContext?.conversationTurn === ConversationTurn.INTERVIEWEE &&
    !isSpeechRecognitionActive
  ) {
    // When it's your turn to speak, start the microphone
    if (recognition === annyang) {
      annyang.start();
    } else {
      recognition?.start();
    }
    setIsSpeechRecognitionActive(true);
  } else if (conversationContext?.conversationTurn === ConversationTurn.INTERVIEWER) {
    // Stop the microphone when it's the AI's turn to speak
    if (isSpeechRecognitionActive) {
      if (recognition === annyang) {
        annyang.abort();
      } else {
        recognition?.stop();
      }
      setIsSpeechRecognitionActive(false);
    }
  }
}, [conversationContext?.conversationTurn, recognition, isSpeechRecognitionActive]);


  const handleTranscriptChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newText = event.target.value;

      // Ensure the user cannot remove or modify the previous input
      if (newText.length >= inputLength) {
        setFinalTranscript(newText);
        setInputLength(newText.length); // Update the allowed length
      }
    },
    [inputLength]
  );

  // Send response when timer runs out
  useEffect(() => {
    if (timerOut) {
      sendResponse();
    }
  }, [timerOut]);

  // Auto-scroll textarea to bottom
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [interimTranscript]);

  // Listen for Enter key press to send response
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        sendResponse();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [finalTranscript, interimTranscript]);
  const sendResponse = async () => {
    if (
      conversationContext?.conversationTurn === ConversationTurn.INTERVIEWEE ||
      (timerOut &&
        conversationContext?.conversationTurn === ConversationTurn.WAITING)
    ) {
      try {
        let message = finalTranscript + interimTranscript;
        if (message.trim() === lastSentMessage.trim()) {
          message += ' ';
        }
        if (recognition !== annyang) recognition?.stop();
        await sendIntervieweeResponse(message, 3);
        setLastSentMessage(message.trim());
        setInterimTranscript('');
        setFinalTranscript('');
        setInputLength(0);
      } catch (error) {
        console.error('Error sending interviewee response:', error);
      }
    }
  };

  return (
    <div
      className="chat-bot-container-main-user"
      style={{
        opacity:
          conversationContext?.conversationTurn === ConversationTurn.INTERVIEWEE
            ? '1'
            : '0.25',
      }}
    >
      <div className="chat-bot-container-main-user-field">
        <textarea
          ref={textareaRef}
          className="chat-bot-container-main-user-field-input"
          value={
            conversationContext?.conversationTurn ===
            ConversationTurn.INTERVIEWEE
              ? finalTranscript + interimTranscript
              : ''
          }
          onChange={handleTranscriptChange}
          onKeyDown={handleKeyDown}
          rows={2}
          disabled={
            conversationContext?.conversationTurn ===
            ConversationTurn.INTERVIEWER
          }
        />
      </div>
      <div
        className="chat-bot-container-main-user-button"
        onClick={sendResponse}
      >
        <div className="chat-bot-container-main-user-button-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.25073 16.4995L2.25031 13.499L5.25073 10.4986"
              stroke="#121212"
              strokeWidth="1.50251"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.00073 13.4982H16.782C19.5364 13.4982 21.7507 11.1858 21.7507 8.43567V7.49817"
              stroke="#121212"
              strokeWidth="1.50251"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="chat-bot-container-main-user-button-content">
          <span>
            {deviceType === 'Mobile' ? 'Submit' : 'Press Enter to send'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Answer);
