import { useEffect, useState } from 'react';
import './index.css';
import {
  SERVICE_URL_JAVA,
  USER_LOGIN_WAY_KEY,
} from '../../../util/AppConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { SessionAnalysis } from '../../../model/SessionAnalysis';
import { exitConversation as getPerformedAnalysis } from '../GeneralCurrentSession/util';
import {
  PATH_CREATE_SESSION,
  PATH_CURRENT_SESSION_SUMMARY,
  PATH_HOME,
} from '../../../util/SiteRoutes';
import { Loader } from '../../Loader';
import { ExitMode } from '../../../model/ExitMode';
import Header from '../../Header';
import SubFooter from '../../SubFooter';
import React from 'react';
import Subheader from '../../SubHeader';

const Rate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [rating, setRating] = useState<number>(0);
  const [hoverRating, setHoverRating] = useState<number>(0);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [message, setMessage] = useState<{ text: string; type: string } | null>(
    null
  );
  const [sessionAnalysis, setSessionAnalysis] =
    useState<SessionAnalysis | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [easeOfUse, setEaseOfUse] = useState<number>(0);
  const [audioQuality, setAudioQuality] = useState<number>(0);
  const [videoQuality, setVideoQuality] = useState<number>(0);
  const [hoverEaseOfUse, setHoverEaseOfUse] = useState<number>(0);
  const [hoverAudioQuality, setHoverAudioQuality] = useState<number>(0);
  const [hoverVideoQuality, setHoverVideoQuality] = useState<number>(0);

  const issueOptions = {
    1: [
      'Bad interpretation',
      'Audio issues',
      'Video issues',
      'Unclear questions',
      'Unclear instructions',
      'Session got stuck',
    ],
    2: [
      'Unclear instructions',
      'Not enough time to respond',
      'Unclear questions',
      'Repetitive questions',
      'Audio issues',
    ],
    3: [
      'Unclear instructions',
      'Not enough time to respond',
      'Unclear questions',
      'Repetitive questions',
      'Audio issues',
    ],
    4: [
      'Insightful questions',
      'Natural conversation flow',
      'Enough time to respond',
      'Positive experience',
    ],
    5: [
      'Insightful questions',
      'Natural conversation flow',
      'Enough time to respond',
      'Positive experience',
    ],
  };

  const additionalRatingOptions = [
    {
      label: 'Ease of use',
      value: easeOfUse,
      setValue: setEaseOfUse,
      hoverValue: hoverEaseOfUse,
      setHoverValue: setHoverEaseOfUse,
    },
    {
      label: 'Audio quality',
      value: audioQuality,
      setValue: setAudioQuality,
      hoverValue: hoverAudioQuality,
      setHoverValue: setHoverAudioQuality,
    },
    {
      label: 'Video quality',
      value: videoQuality,
      setValue: setVideoQuality,
      hoverValue: hoverVideoQuality,
      setHoverValue: setHoverVideoQuality,
    },
  ];

  const [mainRatingOpen, setMainRatingOpen] = useState(true);
  const [additionalRatingOpen, setAdditionalRatingOpen] = useState(false);

  // const toggleMainRating = () => {
  //   setMainRatingOpen(!mainRatingOpen);
  //   if (additionalRatingOpen) setAdditionalRatingOpen(false);
  // };

  // const toggleAdditionalRating = () => {
  //   setAdditionalRatingOpen(!additionalRatingOpen);
  //   if (mainRatingOpen) setMainRatingOpen(false);
  // };

  const toggleMainRating = () => {
    if (mainRatingOpen) {
      setMainRatingOpen(false);
      setAdditionalRatingOpen(true);
    } else {
      setMainRatingOpen(true);
      setAdditionalRatingOpen(false);
    }
  };

  const toggleAdditionalRating = () => {
    if (additionalRatingOpen) {
      setAdditionalRatingOpen(false);
      setMainRatingOpen(true);
    } else {
      setAdditionalRatingOpen(true);
      setMainRatingOpen(false);
    }
  };

  useEffect(() => {
    if (!state?.sessionId) {
      navigate(PATH_CREATE_SESSION);
      return;
    }

    const performAnalysis = async () => {
      const tempSessionAnalysis = await getPerformedAnalysis(
        state.sessionId as string
      );
      setSessionAnalysis(tempSessionAnalysis);
      setLoading(false);
    };

    if (state?.exitMode && state?.exitMode === ExitMode.AUTO) performAnalysis();
    else setLoading(false);
  }, [state, navigate]);

  const getIssues = () => {
    if (rating === 1) return issueOptions[1];
    if (rating === 2 || rating === 3) return issueOptions[2];
    if (rating === 4 || rating === 5) return issueOptions[4];
    return [];
  };

  const updateRating = (index: number) => {
    setRating(index + 1);
    setSelectedIssues([]);
  };

  const toggleIssue = (issue: string) => {
    if (selectedIssues.includes(issue)) {
      setSelectedIssues(selectedIssues.filter((i) => i !== issue));
    } else {
      setSelectedIssues([...selectedIssues, issue]);
    }
  };

  const updateAdditionalRating = (
    setter: React.Dispatch<React.SetStateAction<number>>,
    index: number
  ) => {
    setter(index + 1);
  };

  const submitFeedback = async () => {
    const errorMessages = [];

    if (rating === 0) {
      errorMessages.push('a rating');
    }
    if (rating > 0 && selectedIssues.length === 0) {
      errorMessages.push('at least one issue');
    }

    if (comment.length > 250) {
      errorMessages.push('a comment that does not exceed 250 characters');
    }

    if (errorMessages.length > 0) {
      let errorMessage = 'Please provide ';
      if (errorMessages.length === 1) {
        errorMessage += `${errorMessages[0]}.`;
      } else {
        errorMessage += `${errorMessages
          .slice(0, -1)
          .join(', ')} and ${errorMessages.slice(-1)}.`;
      }

      setMessage({ text: errorMessage, type: 'error' });
      setTimeout(() => {
        setMessage(null);
      }, 2000);
      return;
    }

    const additionalRatings = `Ease of use: ${easeOfUse}, Audio quality: ${audioQuality}, Video quality: ${videoQuality}`;
    const fullComment = `Selected issues: ${selectedIssues.join(
      ', '
    )}. User comment: ${comment}. Additional ratings: ${additionalRatings}`;

    try {
      const response = await fetch(
        `${SERVICE_URL_JAVA}/session/api/v1/submitSessionFeedback?sessionId=${
          state.sessionId
        }&rating=${rating}&comments=${encodeURIComponent(fullComment)}`
      );
      const result = await response.json();

      if (result.status === 'success') {
        if (
          state?.exitMode &&
          state?.exitMode === ExitMode.AUTO &&
          localStorage.getItem(USER_LOGIN_WAY_KEY) === 'normalLogin'
        )
          navigate(PATH_CURRENT_SESSION_SUMMARY, {
            state: { sessionAnalysis: sessionAnalysis },
          });
        else {
          // localStorage.clear();
          navigate(PATH_HOME);
        }
      } else {
        setMessage({
          text: 'An unknown error occurred, please retry.',
          type: 'error',
        });
      }
    } catch (error: any) {
      console.error('Error:', error);
      setMessage({
        text: 'An error occurred while submitting feedback. Please try again later.',
        type: 'error',
      });
    }
  };

  if (loading) {
    return <Loader message="Analysing session summary" />;
  }

  return (
    <React.Fragment>
      <div className='subheader-rate-feedback'><Subheader /></div>
      <div className="rate-parent-container">
        <div className="rate-container">
          {/* Main Rating Section */}
          <div
            className={`rate-container-main-rating accordion_title ${
              mainRatingOpen ? 'active' : ''
            }`}
            onClick={toggleMainRating}
          >
            <div className="rate-container-heading">
              <div className="rate-container-heading1">
                Rate your experience
                <i className={`arrow ${mainRatingOpen ? 'active' : ''}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M3.49805 6.5L7.99903 11.001L12.5 6.5"
                      stroke="#F4EAE7"
                      stroke-width="1.00189"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </i>
              </div>
            </div>
          </div>
          {mainRatingOpen && (
            <div className="rate-container-feedback">
              <div className="rate-container-feedback-rate">
                {Array.from({ length: 5 }, (_, index) =>
                  rating >= index + 1 ? (
                    <svg
                      key={index}
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 26 25"
                      fill="none"
                      onClick={() => updateRating(index)}
                      onMouseEnter={() => setHoverRating(index + 1)}
                      onMouseLeave={() => setHoverRating(0)}
                    >
                      <path
                        d="M12.1288 1.04558C12.5114 0.366931 13.4886 0.36693 13.8712 1.04557L17.2189 6.98512C17.3616 7.2383 17.6074 7.41687 17.8923 7.47435L24.5757 8.82286C25.3393 8.97694 25.6413 9.90638 25.1141 10.4799L20.4997 15.4992C20.303 15.7132 20.2092 16.0021 20.2425 16.2908L21.0253 23.0638C21.1147 23.8377 20.3241 24.4121 19.6158 24.0879L13.4162 21.2505C13.1519 21.1295 12.8481 21.1295 12.5838 21.2505L6.38425 24.0879C5.67589 24.4121 4.88526 23.8377 4.9747 23.0638L5.75747 16.2908C5.79083 16.0021 5.69695 15.7132 5.50024 15.4992L0.885909 10.4799C0.358684 9.90638 0.660696 8.97694 1.42434 8.82286L8.10773 7.47435C8.39263 7.41687 8.63841 7.2383 8.78114 6.98512L12.1288 1.04558Z"
                        fill={
                          hoverRating >= index + 1 || rating >= index + 1
                            ? '#FF8371'
                            : 'none'
                        }
                        stroke={
                          hoverRating >= index + 1 || rating >= index + 1
                            ? '#FF8371'
                            : '#707070'
                        }
                        strokeWidth="1.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      key={index}
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 26 25"
                      fill="none"
                      onClick={() => updateRating(index)}
                      onMouseEnter={() => setHoverRating(index + 1)}
                      onMouseLeave={() => setHoverRating(0)}
                    >
                      <path
                        d="M12.5644 1.29108C12.7557 0.95176 13.2443 0.951762 13.4356 1.29108L16.7834 7.23063C16.9974 7.6104 17.3661 7.87825 17.7934 7.96448L24.4768 9.31298C24.8586 9.39002 25.0096 9.85474 24.746 10.1415L20.1316 15.1608C19.8366 15.4818 19.6958 15.9152 19.7458 16.3482L20.5286 23.1212C20.5733 23.5081 20.178 23.7953 19.8238 23.6332L13.6242 20.7958C13.2279 20.6144 12.7721 20.6144 12.3758 20.7958L6.17617 23.6332C5.82199 23.7953 5.42667 23.5081 5.47139 23.1212L6.25416 16.3482C6.30421 15.9152 6.16339 15.4818 5.86836 15.1608L1.25403 10.1415C0.990416 9.85474 1.14141 9.31298 1.52324 9.23594L8.20665 7.88744C8.63394 7.80121 9.00256 7.53335 9.21655 7.15358L12.5644 1.29108Z"
                        fill={
                          hoverRating >= index + 1 || rating >= index + 1
                            ? '#FF8371'
                            : 'none'
                        }
                        stroke={
                          hoverRating >= index + 1 || rating >= index + 1
                            ? '#FF8371'
                            : '#707070'
                        }
                        strokeWidth="1.5"
                      />
                    </svg>
                  )
                )}
              </div>
              {rating > 0 && (
                <div className="rate-container-feedback-issues">
                  {getIssues().map((issue, index) => (
                    <div
                      key={index}
                      className={`rate-container-feedback-issue ${
                        selectedIssues.includes(issue) ? 'selected' : ''
                      }`}
                      onClick={() => toggleIssue(issue)}
                    >
                      {issue}
                    </div>
                  ))}
                </div>
              )}
              <textarea
                placeholder="Please provide feedback..."
                className="rate-container-feedback-content-input"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                maxLength={250}
              />
            </div>
          )}

          {/* Additional Rating Section */}
          <div
            className={`rate-container-additional-ratings accordion_title ${
              additionalRatingOpen ? 'active' : ''
            }`}
            onClick={toggleAdditionalRating}
          >
            <div className="rate-container-additional-ratings-heading">
              Additional Feedback
              <i className={`arrow ${additionalRatingOpen ? 'active' : ''}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M3.49805 6.5L7.99903 11.001L12.5 6.5"
                    stroke="#F4EAE7"
                    stroke-width="1.00189"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </i>
            </div>
          </div>
          {additionalRatingOpen && (
            <div className="rate-container-feedback-additional-main">
              {additionalRatingOptions.map((category, index) => (
                <div key={index} className="rate-container-feedback-additional">
                  <label>{category.label}</label>
                  <div className="rate-container-feedback-additional-stars">
                    {Array.from({ length: 5 }, (_, starIndex) => (
                      <svg
                        key={starIndex}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 26 25"
                        fill="none"
                        onClick={() =>
                          updateAdditionalRating(category.setValue, starIndex)
                        }
                        onMouseEnter={() =>
                          category.setHoverValue(starIndex + 1)
                        }
                        onMouseLeave={() => category.setHoverValue(0)}
                      >
                        <path
                          d="M12.1288 1.04558C12.5114 0.366931 13.4886 0.36693 13.8712 1.04557L17.2189 6.98512C17.3616 7.2383 17.6074 7.41687 17.8923 7.47435L24.5757 8.82286C25.3393 8.97694 25.6413 9.90638 25.1141 10.4799L20.4997 15.4992C20.303 15.7132 20.2092 16.0021 20.2425 16.2908L21.0253 23.0638C21.1147 23.8377 20.3241 24.4121 19.6158 24.0879L13.4162 21.2505C13.1519 21.1295 12.8481 21.1295 12.5838 21.2505L6.38425 24.0879C5.67589 24.4121 4.88526 23.8377 4.9747 23.0638L5.75747 16.2908C5.79083 16.0021 5.69695 15.7132 5.50024 15.4992L0.885909 10.4799C0.358684 9.90638 0.660696 8.97694 1.42434 8.82286L8.10773 7.47435C8.39263 7.41687 8.63841 7.2383 8.78114 6.98512L12.1288 1.04558Z"
                          fill={
                            category.hoverValue >= starIndex + 1 ||
                            category.value >= starIndex + 1
                              ? '#FF8371'
                              : '#707070'
                          }
                        />
                      </svg>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="rate-container-main">
            <button
              onClick={
                rating > 0 && selectedIssues.length ? submitFeedback : () => {}
              }
              className={`rate-container-submit ${
                rating > 0 && selectedIssues.length ? 'active' : 'inactive'
              }`}
            >
              Submit to see Review
            </button>
          </div>
        </div>
      </div>
      <div className='subfooter-feedback-rate'><SubFooter /></div>
      
    </React.Fragment>
  );
};

export default Rate;
