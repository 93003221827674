import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './InfoBar.css';

const InfoBar: React.FC = () => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // Prevent default anchor behavior
    window.open(
      'https://espello.notion.site/Moving-Beyond-MCQs-to-AI-Powered-Interview-Solutions-4c98efa0cc3147d7af4886c270500e8a',
      '_blank' // Open link in a new tab
    );
  };

  return visible ? (
    <div className="new-home-info-bar">
      <div className="new-home-info-bar-content">
        <span className="new-home-info-bar-heading">
        </span>
        <a href="#" className="new-home-candidate-join" onClick={handleClick}>
          {`Moving Beyond MCQs to AI-Powered Interview Solutions`}
        </a>
      </div>
      <button className="new-home-close-button" onClick={() => setVisible(false)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
          <path d="M11.5023 11.5025L4.50073 4.50098" stroke="#F4EAE7" strokeWidth="1.00189" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.5023 4.50098L4.50073 11.5025" stroke="#F4EAE7" strokeWidth="1.00189" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    </div>
  ) : null;
};

export default InfoBar;
